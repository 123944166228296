// Basic form validations to help with typing and common exceptions.

import {
  isValidEmail,
  isValidPhone,
  isValidCNPJ,
  isValidCPF,
  isValidCEP,
} from '@brazilian-utils/brazilian-utils'
import type { APILeadsCreateLeadGeneratorRequest } from '@lemonenergy/contract-schemas/types'

import { type DiscoList, discoList } from '~/constants/discoDetails'
import type { BrazilianStatesKeys } from '~/constants/geo'
import geo from '~/constants/geo'

const fullNameRegex = /\w{1,64}(\s\w{1,64}){1,256}/

const isFullName = (value?: unknown) => {
  return typeof value === 'string' && fullNameRegex.test(value)
}

const isPhoneNumber = (value?: unknown) => {
  return value && typeof value === 'string' && isValidPhone(value)
}

const isEmail = (value?: unknown) => {
  return value && typeof value === 'string' && isValidEmail(value)
}

const isPostalCode = (value?: unknown) => {
  return value && typeof value === 'string' && isValidCEP(value)
}

const isDiscoKey = (value: string): value is DiscoList => {
  return Object.keys(discoList).includes(value)
}

const isCNPJ = (document?: unknown): document is string => {
  if (!document || typeof document !== 'string') return false

  return isValidCNPJ(document)
}

const isCPF = (document?: unknown): document is string => {
  if (!document || typeof document !== 'string') return false

  return isValidCPF(document)
}

const isDocument = (document?: unknown) => {
  if (!document || typeof document !== 'string') return false

  return isValidCPF(document) || isValidCNPJ(document)
}

const isListedDisco = (value?: unknown) => {
  return (
    value && typeof value === 'string' && isDiscoKey(value) && discoList[value]
  )
}

export type LeadGeneratorBody = APILeadsCreateLeadGeneratorRequest['body']
const isLeadGenerator = (value: {
  [key: string]: unknown
}): value is LeadGeneratorBody => {
  if (
    typeof value.capacity !== 'number' ||
    !isListedDisco(value.disco) ||
    !isFullName(value.name) ||
    !isEmail(value.email) ||
    !isPhoneNumber(value.phoneNumber)
  ) {
    return false
  }

  return true
}

const isBrazilianState = (value?: unknown): value is BrazilianStatesKeys => {
  return !!geo.brazilianStates.find(state => state.code === value)
}

const isBrazilianStateCity = (
  state: BrazilianStatesKeys,
  cityValue?: unknown,
) => {
  if (!cityValue || typeof cityValue !== 'string') return false

  return geo.brazilianStateCities[state].find(
    city => city.toLowerCase() === cityValue.toLowerCase(),
  )
}

const isBillDocumentType = (value?: unknown): value is 'pf' | 'pj' => {
  if (typeof value !== 'string') return false

  return value === 'pf' || value === 'pj'
}

const formValidations = {
  isFullName,
  isPhoneNumber,
  isEmail,
  isListedDisco,
  isLeadGenerator,
  isCNPJ,
  isDocument,
  isPostalCode,
  isBrazilianState,
  isBrazilianStateCity,
  isBillDocumentType,
  isDiscoKey,
  isCPF,
}

export default formValidations
